<template>
	<v-row>
		<v-col cols="2" v-if="this.isVisibleTimeFilter">
			<pui-date-field
				:id="`dateini-giswebtoolbar`"
				v-model="localFilter.dateini"
				:label="$t('gisweb.toolbar.dateini')"
				toplabel
				:min="new Date().setDate(new Date().getDate() - 1)"
			></pui-date-field>
		</v-col>
		<v-col cols="2" v-if="this.isVisibleTimeFilter">
			<pui-date-field
				:id="`dateend-giswebtoolbar`"
				v-model="localFilter.dateend"
				:label="$t('gisweb.toolbar.dateend')"
				toplabel
				:min="localFilter.dateini || null"
			></pui-date-field>
		</v-col>

		<v-col cols="3">
			<pui-select
				:id="`shiptype-${tabmodel}`"
				:attach="`shiptype-${tabmodel}`"
				:label="$t('gisweb.toolbar.shiptype')"
				toplabel
				clearable
				v-model="shiptypeselected"
				modelName="shiptypensw"
				:itemsToSelect="shiptypeselected"
				itemValue="code"
				itemText="description"
				:order="{ name: 'asc' }"
				:multiple="true"
				:fixedFilter="filterByPortAuthorityUser"
			></pui-select>
		</v-col>
		<v-col cols="3">
			<pui-select
				:id="`berth-${tabmodel}`"
				:attach="`berth-${tabmodel}`"
				:label="$t('gisweb.toolbar.berth')"
				toplabel
				clearable
				v-model="berthselected"
				modelName="berth"
				:itemsToSelect="berthselected"
				itemValue="id"
				:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
				:order="{ name: 'asc' }"
				:multiple="true"
			></pui-select>
		</v-col>
		<v-col cols="2">
			<pui-text-field
				:id="`text-giswebtoolbar`"
				v-model="localFilter.text"
				:label="$t('gisweb.toolbar.text')"
				toplabel
				maxlength="100"
			></pui-text-field>
		</v-col>
	</v-row>
</template>

<script>
export default {
	data() {
		return {
			// Clonamos la prop filter
			localFilter: {},
			berthselected: [],
			shiptypeselected: []
		};
	},
	props: {
		filter: {
			type: Object,
			required: true
		},
		isVisibleTimeFilter: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		filterByPortAuthorityUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }]
			};
		},
		tabmodel() {
			return this.$store.getters.getTabModel;
		}
	},
	watch: {
		// Emitir cambios al padre cuando se modifica localFilter
		localFilter: {
			handler(newFilter) {
				console.log('Emitiendo cambios en localFilter');
				const mergedFilter = { ...this.filter, ...newFilter };
				this.$emit('update:filter', mergedFilter);
			},
			deep: true
		},
		// Mantener los cambios en berthselected y shiptypeselected actualizados en localFilter
		berthselected: {
			handler(newBerths) {
				const berths = newBerths.map((berth) => berth.id);
				// Comprueba si estos berths son diferentes a this.filter.berth
				if (JSON.stringify(berths) !== JSON.stringify(this.filter.berth)) {
					this.$set(this.localFilter, 'berth', berths);
				}
			},
			deep: true
		},
		shiptypeselected: {
			handler(newShiptypes) {
				const shiptypes = newShiptypes.map((type) => type.code);
				if (JSON.stringify(shiptypes) !== JSON.stringify(this.filter.shiptype)) {
					this.$set(this.localFilter, 'shiptype', shiptypes);
				}
			},
			deep: true
		}
	}
};
</script>

<style scoped></style>
